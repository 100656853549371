<template>
  <!-- Start preview post -->
  <v-col
    align="center"
    style="background-color:#F0F2F5; border-radius:10px"
  >
    <v-card
      :loading="loading"
      class="mx-auto my-12"
      max-width="374"
    >
      <template slot="progress">
        <v-progress-linear
          color="black"
          height="10"
          indeterminate
        ></v-progress-linear>
      </template>
      <!-- Start header content-->
      <v-row class="pt-6 px-6">
        <v-avatar
          size="40"
        >
          <v-icon>
            {{ icons.mdiAccountCircle }}
          </v-icon>
        </v-avatar>
        <v-col align="left">
          <v-row>
            <p
              class="font-weight-black ml-3 mb-2"
              style="color:black"
            >
              {{ user.name }}
            </p>
            <p
              class="font-weight-black display-1"
              style="color:black; margin-left:5px; margin-top:-16px"
            >
              .
            </p>
            <p
              style="color:#3070c8"
              class="font-weight-bold ml-1"
            >
              Ikuti
            </p>
          </v-row>
          <p
            class="text-caption"
            style="margin-top:-20px; color:black"
          >
            3 Juni
          </p>
        </v-col>
      </v-row>
      <!-- End header content-->

      <!-- Start content -->
      <v-col align="left">
        <p style="color:black">
          {{ post.description }} <span
            style="color:grey"
            class="text-body-2"
          >Lihat selengkapnya</span>
        </p>
      </v-col>
      <!-- End content -->
        
      <v-img
        v-for="(image, i) in post.message_template.attachments"
        :key="i"
        height="250"
        contain
        style="margin-bottom:5px"
        :src="image.url"
      />

      <v-divider class="mx-4"></v-divider>

      <!-- Start footer content-->
      <v-row class="mt-2">
        <v-col
          align="left"
          class="ml-3"
        >
          <p
            class="font-weight-black"
            style="color:black"
          >
            {{ post.post_name }}
          </p>
          <p style="margin-top:-17px">
            Real Estate
          </p>
        </v-col>
        <v-col>
          <v-btn
            class="text-uppercase"
            outlined
            dense
          >
            Whatsapp
          </v-btn>
        </v-col>
      </v-row>
      <!-- End footer content-->

      <v-card-actions>
        <v-col
          justify="center"
          align="center"
        >
          <v-row
            justify="space-between"
            align="center"
            class="mx-2"
          >
            <p>
              <v-icon dense>
                {{ icons.mdiThumbUpOutline }}
              </v-icon> Suka
            </p>
            <p>
              <v-icon dense>
                {{ icons.mdiCommentOutline }}
              </v-icon> Komentar
            </p>
            <p>
              <v-icon dense>
                {{ icons.mdiShare }}
              </v-icon> Bagikan
            </p>
          </v-row>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-col>
  <!-- End preview post -->
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { ref } from '@vue/composition-api'
import {
  mdiChevronLeft,
  mdiClose,
  mdiBrightness1,
  mdiThumbUpOutline,
  mdiCommentOutline,
  mdiShare,
  mdiAccountCircle,
} from '@mdi/js'

export default {
  name: 'PreviewPostFacebook',
  props: {
    imageRef: { type: Array, default: () => [] },
    value: { type: Object, required: true },
  },
  setup() {
    const { isDark } = useAppConfig()
    const search = ref('')

    return {
      icons: {
        mdiChevronLeft,
        mdiClose,
        mdiBrightness1,
        mdiThumbUpOutline,
        mdiCommentOutline,
        mdiShare,
        mdiAccountCircle,
      },
      search,
      isDark,
    }
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    getImages() {
      return this.imageRef.uppy?.getFiles()
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    post: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style>
</style>