<template>
  <div>
    <v-dialog
      v-model="show"
      width="600"
    >
      <v-card>
        <v-toolbar flat>
          <v-row>
            <v-btn
              icon
              dark
              :class="'absolute'"
              @click.stop="show = false"
            >
              <v-icon> {{ icons.mdiClose }}</v-icon>
            </v-btn>
            <v-col class="d-flex justify-center">
              <v-toolbar-title :class="['header', 'title']">
                Reject
              </v-toolbar-title>
            </v-col>
          </v-row>
        </v-toolbar>
        <v-divider></v-divider>
        <v-form @submit="rejectPost">
          <v-row>
            <v-col>
              <v-textarea
                v-model="notesRejected"
                solo
                outlined
                class="mx-6 mt-6"
                name="notesRejection"
                label="Write rejection note..."
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="mb-6 me-6 d-flex justify-end">
              <v-btn
                order="last"
                color="error"
                @click="rejectPost"
              >
                Reject
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
    <v-row 
      justify="center" 
    >
      <v-dialog
        v-if="dialog == true"
        v-model="dialog"
        max-width="400"
      >
        <v-card>
          <v-alert
            dense
            text
            type="error"
          >
            Post successfully <strong>Rejected</strong>
          </v-alert>
          <v-row
            justify="end"
            class="mb-2 mr-1"
          >
            <v-btn
              color="green darken-1"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
          </v-row>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
  name: 'RejectConfirmationDialog',
  setup() {
    return {
      icons: {
        mdiClose,
      },
    }
  },
  props: {
    value: { type: Boolean, required: true },
    item: {
      type: Object,
      default() {
        return {}
      },
    },
    posts: {
      type: Array,
      default() {
        return []
      },
    },
    postNeedApprove: { type: Array, required: true },
    getPostTemplates: { type: Function, required: true },
    showAlert: { type: Boolean },
  },
  data() {
    return {
      notesRejected: '',
      dialog: false,
    }
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    allCampaigns() {
      return this.$store.getters['campaign_manager/getAllCampaignsData']
    },
  },
  methods: {
    async rejectPost() {
      if (this.posts.length) {
        //console.log(this.posts, 'ppopopopop')
        const resultPost = []
        this.allCampaigns.forEach(camp => {
          this.posts.forEach(selectedItem => {
            if (camp.id === selectedItem.idCampaign) {
              const temp = {
                campaign: camp,
                post: {
                  ...selectedItem,
                  approvement: {
                    user: this.user,
                    statusApproval: 'Rejected',
                    notesRejected: this.notesRejected,
                  },
                },
                totalApprover: camp.approvers.length,
              }
              resultPost.push(temp)
            }
          })
        })

        const result = await this.$store.dispatch('marketingCalendar/updateManyStatus', {
          sub_id: this.user.sub_id,
          data: {
            posts: resultPost,
          },
          token: this.user.token,
        })
        if (result.status) {
          this.dialog = true
          this.getPostTemplates()
          this.show = false
        }
      } else {
        const campaigns = this.allCampaigns.filter(campaign => {
          return campaign.id === this.item.idCampaign
        })
        const totalApprover = campaigns[0]?.approvers?.length
        const approvement = {
          user: this.user,
          statusApproval: 'Rejected',
          notesRejected: this.notesRejected,
        }
        const result = await this.$store.dispatch('marketingCalendar/updateStatus', {
          sub_id: this.user.sub_id,
          approvement,
          id: this.item.id,
          totalApprover,
          token: this.user.token,
        })

        if (result.status) {
          this.dialog = true
          this.getPostTemplates()
          this.show = false
        }
      }
    },
  },
}
</script>

<style>
.absolute {
  position: absolute;
  top: 10px;
  left: 10px;
}
</style>
